<style lang="scss">
#app .v-data-table th {
    font-weight: 800;
}
.th-w{
    span{
      font-weight: 800;
    }
    &--id{
        width: 5% !important;
    }
    &--cliente{
        width: 20% !important;
    }
    &--planta{
        width: 20% !important;
    }
    &--creacion{
        width: 20% !important;
    }
    &--programada{
        width: 20% !important;
    }
    &--detalle{
        width: 15% !important;
    }
}
.w-50{
  width: 50% !important;
}
.justify-content-end{
  justify-content: end;
}
.text-decoration-none{
  text-decoration: none !important;
}
.bg-ef {
    background-color: #efefef;
}
.fs11{
  font-size: 11px !important;
}
.flex-direction-column{
  flex-direction: column;
}
</style>

<template>
<!--eslint-disable-->
    <div class="mt-3 px-10">
      <div v-show="tabla">
        <v-row class="align-items-center mb-5">
          <v-col class="text-left" xs="12" sm="12" md="9" lg="9" xl="9" >
              <h4 class="bold">{{$t('solicitudes.ingresada')}}</h4>
          </v-col>
          <v-col xs="12" sm="12" md="3" lg="3" xl="3" >
              <v-text-field
                      class="c-input c-input--search"
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('ensayos.buscar')"
                      dense
                  ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headeritems"
          :items="ListRequest"
          :items-per-page="5"
          class="elevation-1"
          :footer-props="{itemsPerPageText: textofooter}"
        >
          <template v-slot:item.detalle="{ item }">
              <a @click="showSolicitudDetails(item.id)">{{$t('solicitudes.detalle')}}</a>
          </template>
        </v-data-table>
      </div>
      <!-- detallle -->
      <detallesoli v-show="detail" ref="detallesoli"></detallesoli>
    </div>
</template>
<script>
/*eslint-disable*/
import i18n from '../../plugins/il8n';
import Service from '../../services/apis';
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    setSee1: Function,
  },
  components: {
    detallesoli: () => import('./detallesolicitud.vue'),
  },
  computed: {
        textofooter(){
      const textofooter = i18n.t('ensayos.Rowsperpage');
      return textofooter;
    },
    headeritems() {
      const headersoli = [{
        text: 'ID',
        align: 'start',
        value: 'id',
        class: 'th-w--id',
        sortable: false,
      },{
        text: i18n.t('solicitudes.cliente'),
        align: 'center',
        value: 'cliente',
        class: 'th-w--cliente',
        sortable: false,
      },{
        text: i18n.t('solicitudes.planta'),
        align: 'center',
        value: 'planta',
        class: 'th-w--planta',
        sortable: false,
      },{
        text: i18n.t('solicitudes.fecha_creacion'),
        align: 'start',
        value: 'fecha_creacion',
        class: 'th-w--creacion',
        sortable: false,
      },{
        text: i18n.t('solicitudes.fecha_programada'),
        align: 'start',
        value: 'fecha_programada',
        class: 'th-w--programada',
        sortable: false,
      },{
        text: i18n.t('solicitudes.detalle'),
        align: 'center',
        value: 'detalle',
        class: 'th-w--detalle',
        sortable: false,
      }];
      return headersoli;
    },
  },
  data: () => ({
    tabla: true,
    detail: false,
    search: '',

    ListRequest : [],
  }),
  async created() {
    await this.getListRequest();
    this.fnHiddenSolicitudDetailsStore(this.hiddenSolicitudDetails);
    this.fnRemoveListRequestItemStore(this.removeListRequestItem);
    this.fnFormatDateStore(this.formatDate);

  },
  methods: {
    ...mapMutations('solicitudesStore',['fnHiddenSolicitudDetailsStore','fnRemoveListRequestItemStore','fnFormatDateStore']),
    dihola(){
      console.log('hola');
    },
    formatDate( stringDate, lang ='es' ){
      if( stringDate == null || stringDate == '' ){
        return null;
      }
        
      let date = new Date(stringDate)

      let day   = date.getDate()
      let month = date.getMonth() + 1
      let year  = date.getFullYear()

      if(day < 10){
        day = `0${day}`;
      }

      if(month < 10){
        month = `0${month}`;
      }

      let formatDate = '';
      switch( lang ){
        case 'es':
          formatDate = `${day}-${month}-${year}`;
        break;

        case 'en':
          // formatDate = `${year}-${month}-${day}`;
          formatDate = `${day}-${month}-${year}`;
        break;
      
        // default: break;
      }

      // console.log(formatDate);
      return formatDate;
    },
    async getListRequest(){
      // ListRequest
      const data_set = await Service.apiToken('POST', 'getListRequest', this.$ls.storage.token);
      // console.log({'data_set':data_set});

      data_set.response.forEach(item => {
        this.ListRequest.push({
          id              : item.id,
          cliente         : item.local.cliente.nombre,
          planta          : item.local.nombre_local,
          fecha_creacion  : this.formatDate(item.fectime_captura),
          fecha_programada: this.formatDate(item.fecha_presunta),
        });
      });
      
      // console.log({'this.ListRequest':this.ListRequest});
      // return data_set;
    },
    removeListRequestItem(preot_id){
      this.ListRequest = this.ListRequest.filter(item => item.id != preot_id);
      // console.log('removeListRequestItem',this.ListRequest);
    },
    async showSolicitudDetails(preot_id){
      this.$refs.detallesoli.init( preot_id );
      this.tabla  = false;
      this.detail = true;
    },
    async hiddenSolicitudDetails(){
      this.tabla  = true;
      this.detail = false;
    },
  },
};
</script>
